import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, switchMap, of } from 'rxjs';
import { environment } from '@env/environment';
import { ResponseModel } from '@app/core/models/response.model';
import { ROLES } from '@app/core/utils/roles.enums';
import { Records, Record } from '@app/core/models/records.model';
import { PaginateTable } from '@app/core/models/paginate-table.model';
import { PaginateResponse } from '@app/core/models/paginate-response.model';
import { AddFile } from '@app/core/models/add-file.model';

@Injectable()
export class RecordService {
	private routes: any = null;
	// public authenticated: boolean = false;
	private URL: string = environment.apiUrl + 'records/';
	public redirectURL: string = '/';

	private Roles: any = {
		1: ROLES.ADMIN,
		2: ROLES.CARRIER,
		3: ROLES.COMPANY,
	};

	/**
	 * Constructor
	 */
	constructor(@Inject(HttpClient) private http: HttpClient) {
		//
	}

	getRecords(): Observable<Records> {
		return this.http.get(this.URL + 'get-records').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Records>;
				return of(response.data);
			}),
		);
	}

	getRecordsPendings(
		paginate: PaginateTable,
	): Observable<PaginateResponse<Record>> {
		const { URL } = this;
		const { search, page, size } = paginate;

		return this.http
			.get(
				`${URL}get-records-pendings?page=${page}&size=${size}&search=${search}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<Record>>;
					return of(res.data);
				}),
			);
	}

	getRecordsApproved(
		paginate: PaginateTable,
	): Observable<PaginateResponse<Record>> {
		const { URL } = this;
		const { search, page, size } = paginate;

		return this.http
			.get(
				`${URL}get-records-approved?page=${page}&size=${size}&search=${search}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<Record>>;
					return of(res.data);
				}),
			);
	}

	getRecordsIncomplete(
		paginate: PaginateTable,
	): Observable<PaginateResponse<Record>> {
		const { URL } = this;
		const { search, page, size } = paginate;

		return this.http
			.get(
				`${URL}get-records-incomplete?page=${page}&size=${size}&search=${search}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<Record>>;
					return of(res.data);
				}),
			);
	}

	getRecordsRejected(
		paginate: PaginateTable,
	): Observable<PaginateResponse<Record>> {
		const api = this.URL;
		const { search, page, size } = paginate;
		return this.http
			.get(
				`${api}get-records-rejected?page=${page}&size=${size}&search=${search}`,
			)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<Record>>;
					return of(res.data);
				}),
			);
	}

	getRecordByUuid(uuid: string) {
		return this.http.get(this.URL + 'get-record?uuid=' + uuid).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Record>;
				return of(res.data);
			}),
		);
	}

	getRecordByUserId(userId: Number) {
		return this.http.get(this.URL + 'get-record-userId?UserId=' + userId).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Record>;
				return of(res.data);
			}),
		);
	}

	applyPendingStatus(
		request: any,
		companyDocs: AddFile[],
		truckDocs: AddFile[],
		referrals: any[],
	) {
		const formData = new FormData();
		this.appendFormData(formData, '', request);
		// Agregar documentos de la empresa al FormData
		companyDocs.forEach((doc, index) => {
			formData.append(`companyDocs[${index}].name`, doc.name);
			formData.append(
				`companyDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`companyDocs[${index}].file`, doc.file, doc.file.name);
		});

		// Agregar documentos de camiones al FormData
		truckDocs.forEach((doc, index) => {
			formData.append(`truckDocs[${index}].name`, doc.name);
			formData.append(
				`truckDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`truckDocs[${index}].file`, doc.file, doc.file.name);
		});

		referrals.forEach((ref, index) => {
			formData.append(`referrals[${index}].id`, ref.id);
			formData.append(`referrals[${index}].type`, ref.type);
			formData.append(`referrals[${index}].companyName`, ref.companyName);
			formData.append(`referrals[${index}].contact`, ref.contact);
			formData.append(`referrals[${index}].phoneNumber`, ref.phoneNumber);
		});
		return this.http.post(this.URL + 'pending-record', formData).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	applyRejectedStatus(request: any) {
		console.log('request=====>>: ', request);

		const formData = new FormData();
		this.appendFormData(formData, '', request);

		return this.http.post(this.URL + 'rejected-record', formData).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	applyApprovedStatus(
		request: any,
		companyDocs: AddFile[],
		truckDocs: AddFile[],
		referrals: any[],
	) {
		const formData = new FormData();
		this.appendFormData(formData, '', request);
		// Agregar documentos de la empresa al FormData
		companyDocs.forEach((doc, index) => {
			formData.append(`companyDocs[${index}].name`, doc.name);
			formData.append(
				`companyDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`companyDocs[${index}].file`, doc.file, doc.file.name);
		});

		// Agregar documentos de camiones al FormData
		truckDocs.forEach((doc, index) => {
			formData.append(`truckDocs[${index}].name`, doc.name);
			formData.append(
				`truckDocs[${index}].expirationDate`,
				doc.expirationDate.toDateString(),
			);
			formData.append(`truckDocs[${index}].file`, doc.file, doc.file.name);
		});

		referrals.forEach((ref, index) => {
			formData.append(`referrals[${index}].id`, ref.id);
			formData.append(`referrals[${index}].type`, ref.type);
			formData.append(`referrals[${index}].companyName`, ref.companyName);
			formData.append(`referrals[${index}].contact`, ref.contact);
			formData.append(`referrals[${index}].phoneNumber`, ref.phoneNumber);
		});

		return this.http.post(this.URL + 'approved-record', formData).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	/**
	 * Append props to Form Data.
	 * @param formData FormData
	 * @param prefix Prefix
	 * @param data Request
	 */
	private appendFormData(formData: FormData, prefix: string, data: any) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key];
				const newKey = prefix ? `${prefix}.${key}` : key;
				if (typeof value === 'object') {
					this.appendFormData(formData, newKey, value);
				} else {
					formData.append(newKey, value);
				}
			}
		}
	}
}

